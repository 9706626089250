import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import * as config from '../env/env.json';

const cfg = (config as any).default;

export default class ApiGateway {
  static readonly baseUrl: string = cfg.API_GATEWAY_URL;

  public static async get(url: string, params?: any): Promise<any> {
    const jwtToken = await ApiGateway.getJwtToken();
    try {
      const completeUrl = ApiGateway.concatenateURL(ApiGateway.baseUrl, url);
      return await axios.get(completeUrl, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: params,
      });
    } catch (error: any) {
      if (error.response) {
        console.error('Error', error.response);
        throw error.response.data;
      } else {
        console.error('Error', error.message);
        throw error.message;
      }
    }
  }

  public static async post(url: string, request: any, config: AxiosRequestConfig = {}): Promise<any> {
    const jwtToken = await ApiGateway.getJwtToken();
    try {
      const completeUrl = ApiGateway.concatenateURL(ApiGateway.baseUrl, url);
      return await axios.post(completeUrl, request, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        ...config,
      });
    } catch (error: any) {
      if (error.response) {
        console.error('Error', error.response);
        throw error.response.data;
      } else {
        console.error('Error', error.message);
        throw error.message;
      }
    }
  }

  public static async postGenAiFromLambda(url: string, request: any): Promise<any> {
    const jwtToken = await ApiGateway.getJwtToken();
    const accessToken = await ApiGateway.getAccessToken();
    const newRequest = { ...request, 'token': `${accessToken}` };
    try {
      const completeUrl = ApiGateway.concatenateURL(ApiGateway.baseUrl, url);
      return await axios.post(completeUrl, newRequest, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
    } catch (error: any) {
      if (error.response) {
        console.error('Error', error.response);
        throw error.response.data;
      } else {
        console.error('Error', error.message);
        throw error.message;
      }
    }
  }

  public static async delete(url: string): Promise<any> {
    const jwtToken = await ApiGateway.getJwtToken();
    try {
      const completeUrl = ApiGateway.concatenateURL(ApiGateway.baseUrl, url);
      return await axios.delete(completeUrl, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
    } catch (error: any) {
      if (error.response) {
        console.error('Error', error.response);
        throw error.response.data;
      } else {
        console.error('Error', error.message);
        throw error.message;
      }
    }
  }

  private static async getJwtToken(): Promise<string> {
    const currentSession = await Auth.currentSession();
    const jwtToken = currentSession.getIdToken().getJwtToken();
    return jwtToken;
  }

  private static async getAccessToken(): Promise<string> {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();
    return accessToken;
  }

  private static concatenateURL(baseUrl: string, path: string): string {
    if (baseUrl.endsWith('/')) return baseUrl.substring(0, baseUrl.length - 1) + path;
    else return baseUrl + path;
  }
}
